<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-column">
          <span class="doctor-name mb-3">{{ objDoctor.name }}</span>
          <span class="mb-3">
            {{ objDoctor.doctorSpecializationString }}
          </span>
          <v-select
              item-text="name"
              :items="objDoctorDepartment"
              v-model="selectedClinic"
              label="Выберите клинику"
              outlined
              @change="collectOrderData"
              flat
              dense
          ></v-select>
        </div>
      </v-col>
      <v-col>
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";

export default {
  name: "DoctorCardFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
    selectedClinic: "",
  }),
  computed: {
    ...mapGetters({
      selectedDoctorInfo: names.SELECTED_DOCTOR_INFO,
      objDoctor: names.OBJ_DOCTOR_CARD,
      objDoctorDepartment: names.OBJ_DOCTOR_CARD_DEPARTMENT,

    }),
  },
  mounted() {
    this.collectOrderData();
  },
  methods: {
    collectOrderData() {
      this.orderData = {
        departmentName: this.selectedClinic,

        departmentId: null,

        departmentAddress: null,

        departmentPhone: null,

        serviceName: null,

        doctorName: this.objDoctor.name,

        doctorServicePrice: null,

        servicePrice: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.doctor-name {
  font-size: 23px;
  color: black;
}
span {
  font-size: 15px;
  color: grey;
}
</style>
